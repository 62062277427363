'use client'
import { reshapedToken } from '@evenfinancial/cnf-styles'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import type { PropsWithChildren } from 'react'
import { Tabs, Text } from 'reshaped'

export const PageTabs = () => {
  const params = useSearchParams()
  const pathname = usePathname()
  const view = params.get('view') ?? 'relevant'
  const router = useRouter()
  return (
    <Tabs
      variant="pills-elevated"
      size="medium"
      value={view}
      onChange={({ value }) => {
        router.replace(`${pathname}?view=${value}`)
      }}
    >
      <Tabs.List>
        <Item destination="relevant">
          <Text weight={view === 'relevant' ? 'bold' : 'regular'}>By Lender</Text>
        </Item>
        <Item destination="all">
          <Text weight={view === 'all' ? 'bold' : 'regular'}>All Offers</Text>
        </Item>
      </Tabs.List>
    </Tabs>
  )
}

interface Props {
  destination: string
}
const Item = ({ children, destination }: PropsWithChildren<Props>) => {
  return (
    <Tabs.Item
      attributes={{
        style: { background: reshapedToken('color', 'backgroundPrimaryFaded') },
      }}
      value={destination}
    >
      {children}
    </Tabs.Item>
  )
}
