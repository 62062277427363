'use client'

import { type PropsWithChildren, Suspense } from 'react'
import { Badge, type BadgeProps, Modal as ReshapedModal, Text, useToggle } from 'reshaped'
import { FilterIcon } from '../icons/filter'
import { ClientSideForm } from './ClientSideForm'
import { EmbedModal } from './EmbedModal'

interface BadgeState {
  label: string
  variant?: BadgeProps['variant']
}

const generateFilterBadgeState = (activeCount: number): BadgeState => ({
  label: activeCount > 0 ? activeCount.toString() : 'Filter & Sort',
  variant: activeCount > 0 ? undefined : 'outline',
})

interface ModalComponentProps {
  children: React.ReactNode
  open: boolean
  onClose: () => void
  embeddable: boolean
}

const ModalComponent = ({ children, open, onClose, embeddable }: ModalComponentProps) => {
  return embeddable ? (
    <EmbedModal open={open} onClose={onClose}>
      {children}
    </EmbedModal>
  ) : (
    <ReshapedModal
      size={{ m: '40vw' }}
      padding={0}
      position={{ s: 'bottom', m: 'center' }}
      active={open}
      onClose={onClose}
    >
      {children}
    </ReshapedModal>
  )
}

export function FilterModal({
  children,
  activeFilterCount,
  embeddable = false,
}: PropsWithChildren<{ activeFilterCount: number; embeddable: boolean | undefined }>) {
  const { active, activate, deactivate } = useToggle(false)

  const badgeState = generateFilterBadgeState(activeFilterCount)

  return (
    <>
      <Badge
        icon={FilterIcon}
        color="primary"
        size="medium"
        variant={badgeState.variant}
        onClick={activate}
      >
        <Text variant="body-2">{badgeState.label}</Text>
      </Badge>

      <ModalComponent open={active} onClose={deactivate} embeddable={embeddable}>
        <Suspense>
          <ClientSideForm onSubmit={deactivate}>{children}</ClientSideForm>
        </Suspense>
      </ModalComponent>
    </>
  )
}
