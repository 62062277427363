'use client'
import { useSearchParamLinkGenerator } from '@utilities/unsafe'
import { useRouter } from 'next/navigation'
import { X as CloseIcon } from 'react-feather'
import { Badge } from 'reshaped'

interface Props {
  label: string
  parameter: string
}

export const FilterChip = ({ label, parameter }: Props) => {
  const buildLink = useSearchParamLinkGenerator()
  const link = buildLink({ [`filter[${parameter}]`]: null })
  const router = useRouter()

  return (
    <Badge size="large" endIcon={CloseIcon} onClick={() => router.replace(link)}>
      {label}
    </Badge>
  )
}
