'use client'
import { HideSometimes } from '@capabilities/ui-elements'
import { useSearchParams } from 'next/navigation'
import type { PropsWithChildren } from 'react'
export const useClientState = () => {
  const params = useSearchParams()
  const viewMode = params.get('view') ?? 'relevant'
  const enableFilters = viewMode === 'all'

  return { viewMode, enableFilters }
}

export const FilterWrapper = ({ children }: PropsWithChildren) => {
  const { enableFilters } = useClientState()
  return <HideSometimes hide={!enableFilters}>{children}</HideSometimes>
}
