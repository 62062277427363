import { mapKeys, objectify, unique, flat, mapEntries, capitalize, get } from 'radash';
import { match, P } from 'ts-pattern';
import { styleVariants } from '@vanilla-extract/css';
import { clsx } from 'clsx';
export { calc } from '@vanilla-extract/css-utils';

const breakpointValues = {
  s: { maxPx: 459 },
  m: { minPx: 660 },
  l: { minPx: 900 },
  xl: { minPx: 1280 }
};

const createMediaQuery = (styles) => {
  return {
    // @ts-ignore
    "@media": mapKeys(
      styles,
      (key) => match(breakpointValues[key]).with(
        { minPx: P.number.select("minPx") },
        ({ minPx }) => `screen and (min-width: ${minPx}px)`
      ).with(
        { maxPx: P.number.select("maxPx") },
        ({ maxPx }) => `screen and (max-width: ${maxPx}px)`
      ).exhaustive()
    )
  };
};

const camelToKebab = (s) => {
  return s.replace(/([a-z][a-z])([A-Z]|[0-9])/g, "$1-$2").toLowerCase();
};
const getVariableName = (tokenName, tokenType) => {
  const value = ["rs", tokenType && camelToKebab(tokenType), camelToKebab(tokenName)].filter(Boolean).join("-");
  return `--${value}`;
};

const reshapedToken = (type, name, evaluate = true) => {
  try {
    const varName = getVariableName(name, type);
    if (!evaluate)
      return varName;
    return `var(${varName})`;
  } catch (err) {
    console.error(JSON.stringify(err));
    return "";
  }
};

const createGridStyles = (templateAreas, rows, cols) => {
  const selectors = styleVariants(
    objectify(
      unique(flat(templateAreas)),
      (area) => area,
      (area) => area
    ),
    (area) => [{ gridArea: area }]
  );
  const gridAreas = templateAreas.map((row) => `"${row.join(" ")}"`).join("\n");
  const gridProperties = {
    justifyContent: "stretch",
    alignItems: "baseline",
    display: "grid",
    gridTemplateColumns: cols.join(" "),
    gridTemplateRows: rows.join(" "),
    gridTemplateAreas: gridAreas
  };
  return { selectors, gridProperties };
};
const createGridComponents = (selectors, Component) => mapEntries(selectors, (s, v) => [
  capitalize(s),
  (args) => Component({ ...args, className: clsx(v, get(args, "className")) })
]);

export { breakpointValues, createGridComponents, createGridStyles, createMediaQuery, reshapedToken };
