'use client'
import { asEventTrigger } from '@capabilities/actions'
import type { PropsWithChildren } from 'react'
import { Dismissible, Link, Modal, Text, View, useToggle } from 'reshaped'
interface Props {
  logo: JSX.Element
}

const disclaimerModalSize = { s: '100vw', m: '75ch' }
export const DisclaimersModal = ({ children, logo }: PropsWithChildren<Props>) => {
  const { active, activate, deactivate } = useToggle(false)

  return (
    <>
      <Link attributes={asEventTrigger('Product Viewed')} onClick={activate}>
        <Text variant="caption-1">Disclaimers</Text>
      </Link>
      <Modal
        position="center"
        active={active}
        onClose={deactivate}
        size={disclaimerModalSize}
      >
        <View gap={3} padding={2}>
          <Dismissible onClose={deactivate} closeAriaLabel="Close modal">
            {logo}
          </Dismissible>
          {children}
        </View>
      </Modal>
    </>
  )
}
