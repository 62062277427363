import type { KeyboardEvent, MouseEvent, PropsWithChildren } from 'react'
import { Hidden, View } from 'reshaped'

type ModalProps = PropsWithChildren<{
  title?: string
  onClose: () => void
  open: boolean
}>

const modalOverlay = {
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  height: '100%',
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
}

export const EmbedModal = ({ open, onClose, children }: ModalProps) => {
  const handleOverlayClick = (
    e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>,
  ) => {
    // Handle both mouse and keyboard events
    if (
      e.type === 'click' ||
      (e.type === 'keydown' &&
        ((e as KeyboardEvent).key === 'Enter' || (e as KeyboardEvent).key === 'Esc'))
    ) {
      if (e.target === e.currentTarget) {
        onClose()
      }
    }
  }

  return (
    <Hidden hide={!open}>
      <View position="fixed" zIndex={1} inset={0} overflow="auto">
        <div
          role="button"
          tabIndex={0}
          style={modalOverlay}
          onClick={handleOverlayClick}
          onKeyDown={handleOverlayClick}
        >
          <View
            width="95vw"
            maxWidth="408px"
            backgroundColor="white"
            borderRadius="medium"
            paddingBottom={2}
            position="fixed"
            insetTop={{ s: 20, m: 30, l: 40 }}
          >
            {children}
          </View>
        </div>
      </View>
    </Hidden>
  )
}
