import { clsx } from 'clsx'
import type { PropsWithChildren } from 'react'
import { container } from './hidden.css'

export const HideSometimes = ({
  children,
  hide = false,
}: PropsWithChildren<{ hide?: boolean }>) => {
  return (
    <div data-hide={hide || undefined} className={clsx(container)}>
      {children}
    </div>
  )
}
