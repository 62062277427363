'use client'
import { addDDAction } from '@capabilities/actions'
import { usePathname, useRouter } from 'next/navigation'
import type { PropsWithChildren } from 'react'
import { ActionBar, Button, Dismissible, Modal, View } from 'reshaped'

interface Props {
  onSubmit: () => void
}

const spacing = { paddingInline: 6, paddingBlock: 4 }

export const ClientSideForm = ({ children, onSubmit }: PropsWithChildren<Props>) => {
  const pathname = usePathname()
  const router = useRouter()
  function filter(formData: FormData) {
    //@ts-ignore
    const params = new URLSearchParams(formData)
    params.set('view', 'all')
    //@ts-ignore
    router.replace([pathname, decodeURI(params.toString())].join('?'))
    onSubmit()
    addDDAction('filter_changed', Object.fromEntries(params.entries()))
  }
  return (
    <form action={filter}>
      <View maxHeight="90dvh" direction="column">
        <View
          direction="row"
          justify="space-between"
          align="center"
          shadow="raised"
          {...spacing}
        >
          <Modal.Title>Filter & Sort</Modal.Title>
          <Dismissible align="center" onClose={onSubmit} closeAriaLabel="Close modal" />
        </View>
        <View gap={6} overflow="auto" {...spacing}>
          {children}
        </View>
        <ActionBar position="bottom" elevated {...spacing}>
          <Button color="positive" type="submit" fullWidth>
            Apply Changes
          </Button>
        </ActionBar>
      </View>
    </form>
  )
}
